import React from "react";
import Layout from "../components/layouts/layout";

// import ogimage from "../assets/Images/ogImages/og-digiryte.png";
import { Card, Grid } from "@material-ui/core";
import { InlineWidget } from "react-calendly";

import CtaCardSection from "../components/CTASection/ctaCard2";
import FormSection from "../components/FormSection";
import TrustedPartner from "../components/trustedPartner";
import CTAIMAGE2 from "../assets/Images/Clutch/Cta/phone-contact.png";

import LPFooter from "../components/LPFooter";

import PlainCard from "../components/SixPlainCard";
import hoverCardImg1 from "../assets/Images/FinancialCrime/tech/robotic.svg";
import hoverCardImg2 from "../assets/Images/FinancialCrime/tech/ml.svg";
import hoverCardImg3 from "../assets/Images/FinancialCrime/tech/nlp.svg";
import hoverCardImg4 from "../assets/Images/FinancialCrime/tech/cv.svg";
import hoverCardImg5 from "../assets/Images/FinancialCrime/tech/ca.svg";
import hoverCardImg6 from "../assets/Images/FinancialCrime/tech/po.svg";

import HeroSectionSlide from "../components/HeroSectionSlide";

import HeroImage3Slide from "../assets/Images/Homepage/HeroSection/ai-cloud-arm.png";

import TechImage1 from "../assets/Images/Finance/Technology/azure-data-factory.png";
import TechImage2 from "../assets/Images/Finance/Technology/ui-path.png";
import TechImage3 from "../assets/Images/Finance/Technology/informatica.png";
import TechImage4 from "../assets/Images/Finance/Technology/amazon-kinesis.png";
import TechImage5 from "../assets/Images/Finance/Technology/apache-airflow.png";
import TechImage6 from "../assets/Images/Finance/Technology/microsoft-power-automate.png";
import TechImage7 from "../assets/Images/Finance/Technology/microsoft-power-bi.png";
import TechImage8 from "../assets/Images/Finance/Technology/blue-prism.png";

import CompanyImage1 from "../assets/Images/Finance/Companies/barclays.png";
import CompanyImage2 from "../assets/Images/Finance/Companies/hsbc.png";
import CompanyImage3 from "../assets/Images/Finance/Companies/lloyds.png";
import CompanyImage4 from "../assets/Images/Finance/Companies/netwest.png";
import CompanyImage5 from "../assets/Images/Finance/Companies/sc.png";
import CompanyImage6 from "../assets/Images/Finance/Companies/aviva.png";
import CompanyImage7 from "../assets/Images/Finance/Companies/legal-general.png";
import CompanyImage8 from "../assets/Images/Finance/Companies/prudential.png";
import CompanyImage9 from "../assets/Images/Finance/Companies/saga.png";
import CompanyImage10 from "../assets/Images/Finance/Companies/hl.png";
import AccreditedSection from "../components/AccreditedSection";

import MiniCardWithBgImg from "../components/MiniCardWithBgImg";
import specializeCardImg1 from "../assets/Images/FinancialCrime/specialize/1.svg";
import specializeCardImg2 from "../assets/Images/FinancialCrime/specialize/2.svg";
import specializeCardImg3 from "../assets/Images/FinancialCrime/specialize/3.svg";
import specializeCardImg4 from "../assets/Images/FinancialCrime/specialize/4.svg";
import specializeCardImg5 from "../assets/Images/FinancialCrime/specialize/5.svg";
import RevealAnimation from "../components/RevealAnimation";

const FinancialCrimeComplianceAutomationPage = () => {
  const listObjectSlideData = [
    {
      title1: "",
      title2: "Build AI-Powered Financial Crime Compliance",
      sup: "",
      buttonName: "Contact Us",
      buttonUrl: "/contact-us",
      imageUrl: HeroImage3Slide,
      items: [
        "Save 10k hours Annually on AML",
        "Cut KYC procedure time by 50%",
        "Achieve 90% accuracy in detecting fraud",
      ],
      customSlideClass: "customAIArmSlide",
    },
  ];

  const PlainCardData = {
    cardsData: [
      {
        HoverCardWithBtntitle: "Robotic Process Automation (RPA)",
        HoverCardWithBtnsubtitle:
          "RPA is a core function of Intelligent Automation, automating your repetitive and rule-based tasks.",
        HoverCardWithBtnIcon: hoverCardImg1,
        HoverCardWithBtnIconAlt: "Robotic Process Automation (RPA)",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "Machine Learning (ML)",
        HoverCardWithBtnsubtitle:
          "ML brings the power of data-driven insights, predictive capabilities, and adaptive learning to IPA.",
        HoverCardWithBtnIcon: hoverCardImg2,
        HoverCardWithBtnIconAlt: "Machine Learning (ML)",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "Natural Language Processing (NLP)",
        HoverCardWithBtnsubtitle:
          "NLP allows systems to analyze and process your text-based data, enabling sentiment analysis and text classification tasks.",
        HoverCardWithBtnIcon: hoverCardImg3,
        HoverCardWithBtnIconAlt: "Natural Language Processing (NLP)",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "Computer Vision (CV)",
        HoverCardWithBtnsubtitle:
          "CV processes and understands information from your visual data, including images, videos, or speech.",
        HoverCardWithBtnIcon: hoverCardImg4,
        HoverCardWithBtnIconAlt: "Computer Vision (CV)",
        HoverCardIconClass: "plain-card-button-main-img",
      },
      {
        HoverCardWithBtntitle: "Cognitive Automation",
        HoverCardWithBtnIcon: hoverCardImg5,
        HoverCardWithBtnIconAlt: "Cognitive Automation",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle:
          "Cognitive automation leverages AI and machine learning to mimic human thought processes.",
      },
      {
        HoverCardWithBtntitle: "Process Orchestration",
        HoverCardWithBtnIcon: hoverCardImg6,
        HoverCardWithBtnIconAlt: "Process Orchestration",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle:
          "We facilitate process orchestration through intelligent automation, managing and coordinating complex workflows efficiently.",
      },
    ],
  };

  const SpecializeCardData = {
    cardsData: [
      {
        HoverCardWithBtntitle: "Anti Money Laundering (AML)",
        HoverCardWithBtnsubtitle: "",
        HoverCardWithBtnIcon: specializeCardImg1,
        HoverCardWithBtnIconAlt: "Anti Money Laundering (AML)",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardBorder: "card-border-bottom-secondary",
        HoverCardContentList: [
          "Data Extraction and Integration",
          "Case Investigation and Management",
          "Transactions Analysis",
        ],
      },
      {
        HoverCardWithBtntitle: "Know Your Customer (KYC)",
        HoverCardWithBtnIcon: specializeCardImg2,
        HoverCardWithBtnIconAlt: "Know Your Customer (KYC)",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "",
        HoverCardBorder: "card-border-bottom-primary",
        HoverCardContentList: [
          "Opening a New Account",
          "Gathering Data for Analyst Review",
          "Document Understanding",
        ],
      },
      {
        HoverCardWithBtntitle: "Compliance Screening",
        HoverCardWithBtnIcon: specializeCardImg3,
        HoverCardWithBtnIconAlt: "Compliance Screening",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "",
        HoverCardBorder: "card-border-bottom-secondary",
        HoverCardContentList: [
          "Negative News Alerts Investigation",
          "Sanction Alerts Investigation",
          "Closing False Positive Alerts",
        ],
      },
      {
        HoverCardWithBtntitle: "Regulatory Reporting",
        HoverCardWithBtnIcon: specializeCardImg4,
        HoverCardWithBtnIconAlt: "Regulatory Reporting",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "",
        HoverCardBorder: "card-border-bottom-primary",
        HoverCardContentList: [
          "Suspicious Activity Reports (SARs)",
          "Currency Transaction Reports (CTRs)",
          "Counterfeit Currency Reporting (CCR)",
        ],
      },
      {
        HoverCardWithBtntitle: "Fraud Detection",
        HoverCardWithBtnIcon: specializeCardImg5,
        HoverCardWithBtnIconAlt: "Fraud Detection",
        HoverCardIconClass: "plain-card-button-main-img",
        HoverCardWithBtnsubtitle: "",
        HoverCardBorder: "card-border-bottom-secondary",
        HoverCardContentList: [
          "Fraud Alerts Investigation",
          "Dispute Handling",
        ],
      },
    ],
    lastCardData: {
      title: "Let's Talk",
      subTitle: "We’d love to help you to meet your FCC",
      variant: "contained",
      label: "GET IN TOUCH",
      customClass: "miniCrdBgImg-main-border",
      route: "/contact-us",
    },
  };

  return (
    <Layout
      seo={{
        title: "Financial Crime Compliance Automation | Digiryte",
        ogtitle: "Financial Crime Compliance Automation | Digiryte",
        description:
          "Digiryte provides robust financial crime compliance automation solutions to help businesses detect and prevent fraudulent activities, ensuring regulatory compliance and operational efficiency.",
        keywords:
          "financial crime compliance, automation, fraud detection, regulatory compliance, operational efficiency, Digiryte",
      }}
      // isLandingPage={true}
    >
      <HeroSectionSlide
        mainWrapClass="hideAfterBg"
        listObjectData={listObjectSlideData}
        hideController={true}
        customTitleClass="customWrapTitle"
      />
      <div className="common-bg-light">
        <div className="body-container">
          <RevealAnimation component={"span"} className="title-header">
            <span className="red-line3letter">We</span> specialise is automating
          </RevealAnimation>
        </div>
        <div className="body-container">
          <Grid
            container
            direction="row"
            spacing={3}
            className="mt-50 grid-wrap-with-list-item"
          >
            {SpecializeCardData.cardsData.map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4}>
                <Card className="appsgridshadow">
                  <PlainCard
                    HoverCardWithBtnCustomClass={`custom-card-wrap custom-card-icons ${item.HoverCardBorder}`}
                    HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                    HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                    HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                    HoverCardIconClass={item.HoverCardIconClass}
                    HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                    HoverCardContentList={item.HoverCardContentList}
                  />
                </Card>
              </Grid>
            ))}
            <Grid item xs={12} sm={6} md={4} className="card crd-spc">
              <Card className="appsgridshadow mini-card-bg-hght1">
                <MiniCardWithBgImg
                  MiniCardBgImg={SpecializeCardData.lastCardData.image}
                  MiniCardBgImgId="100"
                  tip="Get In Touch"
                  tooltip="yes"
                  title={SpecializeCardData.lastCardData.title}
                  subtitle={SpecializeCardData.lastCardData.subTitle}
                  variant={SpecializeCardData.lastCardData.variant}
                  customClassName="three-mini-card-btn mt-70"
                  cardCustomClass={SpecializeCardData.lastCardData.customClass}
                  label={SpecializeCardData.lastCardData.label}
                  route={SpecializeCardData.lastCardData.route}
                />
              </Card>
            </Grid>
          </Grid>
        </div>
      </div>

      <div className="mt-70 body-container">
        <RevealAnimation component={"span"} className="title-header">
          We leverage these technologies to <br />{" "}
          <span className="red-line3letter">auto</span>mate your processes
        </RevealAnimation>
      </div>
      <div className="body-container">
        <Grid className="mt-50" container direction="row" spacing={3}>
          {PlainCardData.cardsData.map((item, index) => (
            <Grid key={index} item xs={12} sm={6} md={4}>
              <Card className="appsgridshadow">
                <PlainCard
                  HoverCardWithBtnCustomClass={"custom-card-wrap"}
                  HoverCardWithBtntitle={item.HoverCardWithBtntitle}
                  HoverCardWithBtnIcon={item.HoverCardWithBtnIcon}
                  HoverCardWithBtnIconAlt={item.HoverCardWithBtnIconAlt}
                  HoverCardIconClass={item.HoverCardIconClass}
                  HoverCardWithBtnsubtitle={item.HoverCardWithBtnsubtitle}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>

      <div className="common-o-bg mt-70">
        <CtaCardSection
          title={
            <>
              <span className="animationTitle">Contact Us </span>
              to build your <br />
              success story
            </>
          }
          // titleClassName="customTitle"
          imageUrl={CTAIMAGE2}
          imgClassName="custom-cta-img custom-cta-darken"
        />
      </div>
      <div className="">
        <TrustedPartner
          title={
            <>
              Cutting-Edge Technologies We <br /> Utilise Include, But Are Not
              Limited to
            </>
          }
          images={[
            TechImage1,
            TechImage2,
            TechImage3,
            TechImage4,
            TechImage5,
            TechImage6,
            TechImage7,
            TechImage8,
          ]}
          wrapClassName="bg-white"
          imgContainerClassName=" max-w-md mx-auto"
          showAndMore
        />
      </div>
      <AccreditedSection />
      <div className="">
        <TrustedPartner
          title={
            <>
              Leading BFSI Companies <br /> Embracing Modern Technologies <br />{" "}
              Offshore: When Will You?
            </>
          }
          imgContainerClassName=" max-w-lg mx-auto"
          wrapClassName="bg-white"
          images={[
            CompanyImage1,
            CompanyImage2,
            CompanyImage3,
            CompanyImage4,
            CompanyImage5,
            CompanyImage6,
            CompanyImage7,
            CompanyImage8,
            CompanyImage9,
            CompanyImage10,
          ]}
        />
      </div>
      <div className="mt-20">
        <div className="body-container">
          {/* <!-- Calendly inline widget begin --> */}
          <InlineWidget url="https://calendly.com/harry-digiryte/discoverycall" />
          {/* <!-- Calendly inline widget end --> */}
        </div>
      </div>

      <div className="common-bg-light mt-70">
        <FormSection
          subTitle="From process to Automation"
          title="Build and Enhance would class FCC"
        />
      </div>
      <LPFooter />
    </Layout>
  );
};

export default FinancialCrimeComplianceAutomationPage;
