import React from "react";
import Button from "../Button";
import "./SixPlainCard.scss";

const plainCard = (props) => {
  return (
    <div className={`six-plain-card-main ${props.HoverCardWithBtnCustomClass}`}>
      <div>
        <div className="six-plain-card-title">
          {props.HoverCardWithBtntitle}
        </div>
        <div className={props.HoverCardWithBtnContentClass}>
          {props.HoverCardWithBtnContent}
        </div>
        {props?.HoverCardWithBtnsubtitle && (
          <div className="six-plain-card-subtitle">
            {props?.HoverCardWithBtnsubtitle}
          </div>
        )}
        {props?.HoverCardContentList?.length > 0 && (
          <div className="content-list-wrap">
            {props?.HoverCardContentList?.map((item, index) => (
              <div key={index} className="content-list-item">
                <div style={{ display: "flex" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="24" height="24" rx="12" fill="#E89F38" />
                    <path
                      d="M18 5.6001L17.8364 5.48499L17.8363 5.48514L17.8359 5.48579L17.834 5.48842L17.8265 5.49906L17.7965 5.54139C17.77 5.57882 17.7303 5.63447 17.6783 5.70716C17.5743 5.85253 17.4205 6.06602 17.2221 6.33814C16.8253 6.88239 16.2497 7.66109 15.5354 8.59825C14.1064 10.4728 12.1229 12.9802 9.90367 15.513C9.37776 16.1129 8.90051 16.3319 8.49311 16.358C8.08233 16.3843 7.70105 16.2172 7.36422 15.9613C7.02742 15.7055 6.75049 15.372 6.55592 15.0977C6.45924 14.9613 6.38428 14.8416 6.33373 14.7563C6.30847 14.7137 6.28938 14.6798 6.27678 14.657L6.26281 14.6312L6.25956 14.6251L6.25889 14.6238L6.25886 14.6238C6.25884 14.6237 6.25882 14.6237 6.25881 14.6237L6.2588 14.6236C6.2192 14.5471 6.13499 14.5045 6.04984 14.518C5.96465 14.5315 5.89771 14.5981 5.88377 14.6832C5.69619 15.829 5.8337 16.7101 6.19672 17.3492C6.56149 17.9913 7.14102 18.3659 7.78759 18.5186C9.06416 18.8201 10.625 18.2641 11.4303 17.1919L11.4304 17.1919C12.5341 15.7221 14.2229 12.8422 15.6303 10.341C16.3354 9.08808 16.9719 7.92642 17.4321 7.07804C17.6622 6.65382 17.8483 6.30788 17.9769 6.06794C18.0412 5.94797 18.0911 5.8545 18.125 5.791L18.1635 5.71865L18.1733 5.70014L18.1758 5.69545L18.1764 5.69426L18.1766 5.69395C18.1766 5.69388 18.1767 5.69385 18 5.6001ZM18 5.6001L17.8365 5.48495C17.8973 5.39862 18.0147 5.37442 18.1047 5.42966C18.1946 5.48491 18.2262 5.60058 18.1767 5.69385L18 5.6001Z"
                      fill="white"
                      stroke="white"
                      strokeWidth="0.4"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="content-list-title">{item}</div>
              </div>
            ))}
          </div>
        )}
        {props.HoverCardWithBtnlabel && <div className="six-white-line"></div>}
        <div
          className={`six-plain-card-button-main ${props.HoverCardExtraiconclassname}`}
        >
          {props.HoverCardWithBtnlabel && (
            <Button
              customClassName="six-plain-card-button hover-card-button"
              variant={props.HoverCardWithBtnvariant}
              color={props.HoverCardWithBtncolor}
              label={props.HoverCardWithBtnlabel}
              routepath={props.route}
            />
          )}
          {props.HoverCardWithBtnIcon && (
            <img
              className={
                props.customHeight
                  ? `custom-height ${props.HoverCardIconClass}`
                  : `${props.HoverCardIconClass}`
              }
              src={props.HoverCardWithBtnIcon}
              alt={props.HoverCardWithBtnIconAlt}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default plainCard;
